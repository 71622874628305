import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "src/contexts/AppContext";

export default function Blog() {
  const { filterPosts, posts, uniqueCategory, setCategory } = useContext(AppContext)

  return (
    <div className="ps-0" style={{ overflowX: "hidden" }}>
      {/* header */}
      <div style={{ backgroundColor: "#212B36" }}>
        <h1 className="text-white text-center" style={{ padding: "100px 0" }}>
          Blogs
        </h1>
      </div>
      {/* blogs */}
      <div className="container-fluid">
        <div className="row mt-3 d-flex justify-content-between">
          <div className="col-md-3 ">
            {posts &&
              [...uniqueCategory].map((category) => {
                return (
                  <div key={category}>
                    <button className="btn w-100 mt-2 blog-btn"
                            style={{
                              backgroundColor: '#f8f9fa',
                              border: '1px solid #ced4da',
                              color: '#495057',
                              padding: '20px',
                              textAlign: 'left',
                              transition: 'all 0.3s ease',

                            }}
                            onClick={() => setCategory(category)}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = '#05487a';
                              e.target.style.color = '#ffffff';
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = '#f8f9fa';
                              e.target.style.color = '#495057';
                            }}
                    >
                      {category}
                    </button>


                  </div>
                );
              })}
          </div>
          <div className="col-md-9">
            <div className="container">
              <div className="row">
                {filterPosts.map((post) => (
                  <div className="col-md-4 col-12 mt-3 mb-3" key={post._id}>
                    <Link style={{ textDecoration: 'none' }} to={`/blog/${post.slug}`}>
                      <div className="card h-100">
                        <div className="card-body">
                          <h5 className="card-title" style={{ fontWeight: 'normal' }}>
                            {post.title.length > 40 ? post.title.slice(0, 40) + '...' : post.title}
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
