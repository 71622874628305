import { createContext, useContext, useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { API_URL, SERVER_URL } from '../config/constants';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const theme = useTheme();
  const initialSearchBody = {
    countries: [],
    degree_levels_types: [],
    disciplines: [],
    course: [],
  };
  const [coursesData, setCoursesData] = useState({});
  const [searchBody, setSearchBody] = useState(initialSearchBody);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [disciplinesData, setDisciplinesData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [filterPosts, setFilterPosts] = useState([])
  const [uniqueCategory, setUniqueCategory] = useState(new Set());
  const Getallposts = async () => {
    await fetch("https://dunya-course-finder-blogs.vercel.app/api/auth/getallposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        const newCategory = new Set(data.map((post) => post.category));
        setUniqueCategory(newCategory);
        if (newCategory.size > 0) {
          setCategory([...newCategory][0]);
        }
      });
  };

  useEffect(() => {
    Getallposts();
  }, [])
  useEffect(() => {
    if (category === null) {
      setFilterPosts([])
    } else {
      const filterPost = posts.filter((post) => post.category === category);
      setFilterPosts(filterPost)
    }
  }, [category, posts]);
  const countriesData = [
    {
      name: 'UK',
      slug: 'uk',
    },
    {
      name: 'USA',
      slug: 'usa',
    },
    {
      name: 'Canada',
      slug: 'canada',
    },
    {
      name: 'Australia',
      slug: 'australia',
    },
    {
      name: 'Dubai',
      slug: 'uae',
    },
    {
      name: 'Finland',
      slug: 'finland',
    },
    {
      name: 'Cyprus',
      slug: 'cyprus',
    },
    {
      name: 'Ireland',
      slug: 'ireland',
    },
    {
      name: 'Turkey',
      slug: 'turkey',
    },
    {
      name: 'Germany',
      slug: 'germany',
    },
    {
      name: 'Denmark',
      slug: 'denmark',
    },
  ];
  const qualificationData = [
    {
      name: 'Undergraduate',
      slug: 'undergradute',
    },
    {
      name: 'Postgraduate',
      slug: 'postgradute',
    },
  ];

  const resetSearchBody = () => {
    setSearchBody(initialSearchBody);
    return initialSearchBody;
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${SERVER_URL}/discipline/`);
        setDisciplinesData(data.results);
      } catch (err) {
        console.log('error in fetching disciplines', err);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    const handleFiltersOpen = () => {
      if (window.innerWidth <= theme.breakpoints.values.sm) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleFiltersOpen();
    window.addEventListener('resize', handleFiltersOpen);

    return () => {
      window.removeEventListener('resize', handleFiltersOpen);
    };
  }, [theme.breakpoints.values.sm]);

  const getSearchResults = async ({ link = `${API_URL}/?page=1`, body = searchBody } = {}) => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(link, JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setCoursesData(data);
      return data;
    } catch (err) {
      console.error('error in fetching courses data', err);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToResult = () => {
    const element = document.getElementById('search-result');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <AppContext.Provider
      value={{
        coursesData,
        getSearchResults,
        searchBody,
        setSearchBody,
        isLoading,
        disciplinesData,
        countriesData,
        qualificationData,
        resetSearchBody,
        scrollToResult,
        isMobileView,
        filterPosts,
        posts,
        uniqueCategory,
        setCategory
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;
