import React from 'react';
import Studygif from '../../Assets/studyinuk.gif';

const StudyAbroad = () => {
  return (
    <div>
      <img
        src={Studygif}
        alt="Study abroad"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    </div>
  );
};

export default StudyAbroad;
