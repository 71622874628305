import React from 'react'
import {TipsAndUpdatesOutlined, ScienceOutlined, SchoolOutlined, AirplaneTicketOutlined, HeadsetMicOutlined, HomeWorkOutlined, Diversity1Outlined, HailOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material'

function Services() {
  return (
    <div>
      <section className='services'>
        <div className='container'>
            <div className='row'>
            <Typography variant='h3' sx={{fontFamily: 'Public Sans'}} className='mb-4 text-center'>What <span style={{ color: '#f57c00' }}>We Do</span> </Typography>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <TipsAndUpdatesOutlined className='service-icon'/>
                        <h5>Career Counselling</h5>
                        <p>Our career counselling service provides personalized guidance from experienced education consultants.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <ScienceOutlined className='service-icon'/>
                        <h5>Courses & University Search</h5>
                        <p>Our course finder tool helps you search for courses and universities that match your academic goals.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <SchoolOutlined className='service-icon'/>
                        <h5>Study Abroad Scholarships</h5>
                        <p>We help you secure study abroad scholarships to make your international education affordable.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <AirplaneTicketOutlined className='service-icon'/>
                        <h5>Apply For Study Visa</h5>
                        <p>Our consultants assist you in applying for your study visa with ease and efficiency.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <HeadsetMicOutlined className='service-icon'/>
                        <h5>Visa Interview Preparation</h5>
                        <p>Our experts provide comprehensive visa interview preparation to ensure your success.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <HomeWorkOutlined className='service-icon'/>
                        <h5>Accomodation</h5>
                        <p>We assist you in finding comfortable and affordable accommodation abroad.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body'>
                        <Diversity1Outlined className='service-icon'/>
                        <h5>Pre Departure Briefing</h5>
                        <p>Our pre-departure briefing prepares you thoroughly for your study abroad experience.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 services">
                    <div className='card consultancy-services'>
                        <div className='card-body cust-body'>
                        <HailOutlined className='service-icon' />
                        <h5>Education Consultants</h5>
                        <p>Our consultants assist you in applying for your study visa with ease and efficiency.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Services
