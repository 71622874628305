import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import Layout from './layouts/simple';
//
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import DetailsPage from './pages/DetailsPage';
// 
import Admin from './Blog/Admin'
import Blogcategory from './Blog/Blogcategory';
import Allposts from './Blog/Allposts';
import Addcategory from './Blog/Addcategory';
import Allcategory from './Blog/Allcategory';
import Board from './Blog/Board';
import Blog from "./Blog/Blog"
import Blogcat from "./Blog/Blogcat"
import Login from './Blog/AdminLogin';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <Layout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: 'details', element: <DetailsPage /> },
        { path: 'blog', element: <Blog /> },
        { path: 'blog/:postSlug', element: <Blogcat /> },
        { path: 'admin', element: <Login /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        {
          path: 'adminPanel',
          element: <Admin />,
          children: [
            { index: true, element: <Navigate to="addblog" /> },
            { path: 'addblog', element: <Blogcategory /> },
            { path: 'allposts', element: <Allposts /> },
            { path: 'addcategory', element: <Addcategory /> },
            { path: 'allcategory', element: <Allcategory /> },
            { path: 'board', element: <Board /> },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
