import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Box, CardMedia, Divider, CardContent, Typography, Button } from '@mui/material';

import GridSection from './GridSection';

const GridView = ({ result, setIsOpen, setSelectedCourse }) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <CardMedia
          component="img"
          alt={result.institute?.institute_name}
          src={result.institute.logo}
          height={150}
          sx={{ objectFit: 'contain' }}
        />
        <Divider />
        <CardContent sx={{ pt: 1, px: 2, '&:last-child': { pb: 0 } }}>
          <Typography variant="subtitle2" fontSize="1rem">
            {result.name?.length <= 50 ? result.name : `${result.name.slice(0, 45)}...`}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ mt: -1 }}>
        <CardContent sx={{ pt: 0, pb: 1, px: 2 }}>
          <GridSection result={result} />
        </CardContent>
        <Divider />
        <CardContent sx={{ p: 2 }}>
          <Button
            size="large"
            variant="contained"
            sx={{ width: 1 }}
            onClick={() => {
              setSelectedCourse(result);
              setIsOpen((pre) => !pre);
            }}
          >
            Apply
          </Button>
        </CardContent>
      </Box>
    </Card>
  </Grid>
);

GridView.propTypes = {
  result: PropTypes.object,
  setIsOpen: PropTypes.func,
  setSelectedCourse: PropTypes.func,
};

export default GridView;
