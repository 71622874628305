import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box, Paper } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import offices from '../../_mock/office';
import { useMediaQuery } from '@mui/material';

const CardList = () => {
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [mapUrl, setMapUrl] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {

    if (!isMobile && !selectedOffice) {
      const headOffice = offices.find((office) => office.city === 'Head');
      if (headOffice) {
        setSelectedOffice(headOffice);
        setMapUrl(headOffice.mapUrl);
      }
    }
  }, [isMobile, selectedOffice]);

  const handleOfficeClick = (office) => {
    setSelectedOffice(office);
    setMapUrl(office.mapUrl);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <Box sx={{ py: 4, px: 4 }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold' , textAlign: 'center',mb:5 }}>
        Explore Our<span style={{ color: '#f57c00' }}> Offices</span>
      </Typography>
      {isMobile ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            {offices.map((office) => (
              <Grid item xs={12} key={office.id}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleOfficeClick(office)}
                  sx={{
                    textTransform: 'none',
                    fontSize: '20px',
                    padding: '12px 24px',
                    borderRadius: '20px',
                    backgroundColor:
                      selectedOffice?.id === office.id ? '#f77421' : '#0662a5',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedOffice?.id === office.id ? '#f77421' : '#054a82',
                    },
                  }}
                >
                  {office.city} Office
                </Button>

                {selectedOffice?.id === office.id && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <Paper sx={{ p: 0, height: '100%' }}>
                        <iframe
                          key={refreshKey}
                          src={mapUrl}
                          width="100%"
                          height="350px"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          p: 4,
                          bgcolor: '#0662a5',
                          color: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          {selectedOffice.city}
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <PhoneIcon sx={{ mr: 1 }} />
                          <Typography variant="body1">{selectedOffice.phone}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <AccessTimeIcon sx={{ mr: 1 }} />
                          <Typography variant="body1">Hours: {selectedOffice.time}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LocationOnIcon sx={{ mr: 1 }} />
                          <Typography variant="body1">{selectedOffice.address}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LocationOnIcon sx={{ mr: 1 }} />
                          <Typography variant="body1">{selectedOffice.addresstwo}</Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            {offices.map((office) => (
              <Grid item key={office.id}>
                <Button
                  variant="contained"
                  onClick={() => handleOfficeClick(office)}
                  sx={{
                    textTransform: 'none',
                    fontSize: '20px',
                    borderRadius: '20px',
                    backgroundColor:
                      selectedOffice?.id === office.id ? '#f77421' : '#0662a5',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedOffice?.id === office.id ? '#f77421' : '#054a82',
                    },
                  }}
                >
                  {office.city} Office
                </Button>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 0, height: '100%' }}>
                <iframe
                  key={refreshKey}
                  src={selectedOffice?.mapUrl}
                  width="100%"
                  height="350px"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  p: 4,
                  height: '100%',
                  bgcolor: '#0662a5',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h3" gutterBottom>
                  {selectedOffice?.city}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PhoneIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">{selectedOffice?.phone}</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AccessTimeIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Hours: {selectedOffice?.time}</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">{selectedOffice?.address}</Typography>
                </Box>

                {selectedOffice?.addresstwo && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">{selectedOffice.addresstwo}</Typography>
                  </Box>
                )}


              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CardList;
