import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// mui
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  useScrollTrigger,
  Box,
  Stack,
  Menu,
  MenuItem,
  IconButton,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import MenuIcon from '@mui/icons-material/Menu';

import ContactDialog from '../dialogs/ContactDialog';
import Logo from '../logo';
// import useScrollSpy from '../../hooks/useScrollSpy';

const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const TextLinks = (props) => {
  const location = useLocation();
  const ids = ['#offices', '#countries', '#why-us'];

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ ...props.sx }}>
      <StyledLink
        component={HashLink}
        smooth
        to={`/${ids[0]}`}
        onClick={props.handleClick}
        color={location.hash === ids[0] ? 'primary' : 'text.primary'}
      >
        <Typography variant="subtitle2">Our Offices</Typography>
      </StyledLink>
      <StyledLink
        component={HashLink}
        smooth
        to={`/${ids[1]}`}
        onClick={props.handleClick}
        color={location.hash === ids[1] ? 'primary' : 'text.primary'}
      >
        <Typography variant="subtitle2">Countries We Offer</Typography>
      </StyledLink>
      <StyledLink
        component={HashLink}
        smooth
        to={`/${ids[2]}`}
        onClick={props.handleClick}
        color={location.hash === ids[2] ? 'primary' : 'text.primary'}
      >
        <Typography variant="subtitle2">Why Us?</Typography>
      </StyledLink>

      <StyledLink
        component={HashLink}
        smooth
        to="/blog"
        onClick={props.handleClick}
        color='text.primary'
      >
        <Typography variant="subtitle2">Blog</Typography>
      </StyledLink>
    </Stack>
  );
};

const SocialLinks = (props) => (
  <Stack direction="row" spacing={1} sx={{ alignItems: 'center', ...props.sx }}>
    <a href="https://twitter.com/dunyaconsultant" target="_blank" rel="noreferrer">
      <TwitterIcon color="primary" />
    </a>
    <a href="https://www.facebook.com/DunyaConsultant" target="_blank" rel="noreferrer">
      <FacebookIcon color="primary" />
    </a>
    <a href="https://www.youtube.com/@dunyaconsultants" target="_blank" rel="noreferrer">
      <YouTubeIcon color="primary" />
    </a>
    <a href="https://www.linkedin.com/company/dunya-consultants/" target="_blank" rel="noreferrer">
      <LinkedInIcon color="primary" />
    </a>
    <a href="https://www.instagram.com/dunyaconsultant/" target="_blank" rel="noreferrer">
      <InstagramIcon color="primary" />
    </a>
    <a href="https://www.pinterest.com/dunyaconsultants/" target="_blank" rel="noreferrer">
      <PinterestIcon color="primary" />
    </a>
  </Stack>
);

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(null);

  const handleOpenNavMenu = (e) => setOpenNavMenu(e.currentTarget);

  const handleCloseNavMenu = () => setOpenNavMenu(null);

  const handleDialogClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <ContactDialog isOpen={isOpen} handleDialogClose={handleDialogClose} />
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            bgcolor: 'background.paper',
            backdropFilter: 'saturate(180%) blur(10px)',
            background: 'rgba(255,255,255,.8)',
          }}
        >
          <Toolbar
            sx={{ display: 'flex', columnGap: 2, justifyContent: 'space-between', alignItems: 'center', height: 70 }}
          >
            <Logo />

            {/* Desktop menu */}
            <TextLinks sx={{ display: { xs: 'none', md: 'flex' } }} />
            <SocialLinks sx={{ display: { xs: 'none', md: 'flex' } }} />
            <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ display: { xs: 'none', md: 'block' } }}>
              Contact Us
            </Button>

            {/* Mobile menu */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton color="primary" size="large" onClick={handleOpenNavMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={openNavMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(openNavMenu)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none', top: 40 },
                }}
              >
                <Box
                  component="section"
                  sx={{
                    columnGap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    mx: 2,
                    my: 1,
                  }}
                >
                  <TextLinks handleClick={handleCloseNavMenu} />
                  <Button
                    variant="contained"
                    sx={{ my: 2 }}
                    onClick={() => {
                      handleCloseNavMenu();
                      setIsOpen(true);
                    }}
                  >
                    contact us
                  </Button>
                  <SocialLinks />
                </Box>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar id="back-to-top-anchor" sx={{ height: 70 }} />
    </>
  );
};

export default Header;
