import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useAppContext } from '../../../contexts/AppContext';

const SortSection = () => {
  const { setSearchBody, getSearchResults, searchBody } = useAppContext();

  const handleSortChange = (e) => {
    const { value } = e.target;
    setSearchBody((pre) => ({ ...pre, order: value }));
    getSearchResults({ body: { ...searchBody, order: value } });
  };
  return (
    <FormControl size="small" sx={{ minWidth: 150 }}>
      <InputLabel id="sort-by-filter-label">Sort By</InputLabel>
      <Select labelId="sort-by-filter-label" label="Sort By" value={searchBody.order ?? ''} onChange={handleSortChange}>
        <MenuItem value={0}>Reset</MenuItem>
        <MenuItem value={1}>Fee Low To High</MenuItem>
        <MenuItem value={2}>Fee High To Low</MenuItem>
        <MenuItem value={3}>
          Duration <ArrowUpwardIcon fontSize="small" />
        </MenuItem>
        <MenuItem value={4}>
          Duration <ArrowDownwardIcon fontSize="small" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SortSection;
