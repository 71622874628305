import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';

const useSearchHook = () => {
  const navigate = useNavigate();
  const { setSearchBody } = useAppContext();
  const [error, setError] = useState('');
  const [courseText, setCourseText] = useState('');
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedQualification, setSelectedQualification] = useState({});

  const handleChange = (value, name) => {
    setError('');
    if (name === 'countryData') {
      setSelectedCountry(value);
    } else if (name === 'disciplineData') {
      setSelectedDisciplines(value);
    } else if (name === 'qualificationData') {
      setSelectedQualification(value);
    } else if (name === 'course') {
      setCourseText(value);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!selectedDisciplines.length && !selectedCountry.slug && !selectedQualification.slug && !courseText) {
      setError('At least one field is required');
      return;
    }

    const body = {
      course: courseText.length ? [courseText] : [],
      disciplines: selectedDisciplines?.length ? selectedDisciplines.map((item) => item.slug) : [],
      countries: selectedCountry?.slug ? [selectedCountry.slug] : [],
      degree_levels_types: selectedQualification?.slug ? [selectedQualification.slug] : [],
    };
    setSearchBody(body);

    navigate('/details');
  };

  return { handleChange, handleSearch, error, courseText };
};

export default useSearchHook;
