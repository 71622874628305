import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Slider from 'react-slick';
import universityLogos from '../../_mock/universitylogo';

const GlobalPartners = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, dots: false,autoplaySpeed: 1500 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2, dots: false,autoplaySpeed: 1500 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, dots: false,autoplaySpeed: 1500 }
      },
    ],
  };

  return (
    <Box sx={{ py: 10, textAlign: 'center' }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
        Our Global <span style={{ color: '#f57c00' }}>Partners</span>
      </Typography>

      <Divider
        sx={{
          width: '50%',
          margin: 'auto',
          borderBottomWidth: 3,
          my: 2,
          backgroundColor: '#1976d2',
        }}
      />

      <Box sx={{ mt: 4 }}>
        <Slider {...settings}>
          {universityLogos.map((logo, index) => (
            <Box key={index} sx={{ px: 2 }}>
              <img
                src={logo}
                alt={`Partner ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  maxHeight: '100px',
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default GlobalPartners;
