import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  AppBar,
  Autocomplete,
  useScrollTrigger,
  Collapse,
  Grid,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../components/iconify';

import { useAppContext } from '../../contexts/AppContext';
import useSearchHook from './hooks/useSearchHook';

const StyledWrapper = styled('div')(() => ({
  backgroundImage: `url("https://timescoursefinder.com/static/media/image.434521e9.webp")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  background: 'rgba(0,0,0,.5)',
  paddingBlock: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SearchBar = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  background: 'white',
  borderRadius: '10px',
  padding: theme.spacing(1),
  margin: theme.spacing(3, 2),
  [[theme.breakpoints.up('sm')]]: {
    padding: theme.spacing(1.5),
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  left: 'auto',
  right: 'auto',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  top: 70,
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Hero = ({ ...props }) => {
  const theme = useTheme();
  const { disciplinesData, countriesData, qualificationData, isMobileView } = useAppContext();

  const {
    handleSearch,
    handleChange,
    isDisabled,
    resetSearchBar,
    searchText,
    filterDisciplines,
    filterCountry,
    filterQualification,
    handleReset,
    isFieldChanged,
    isFilterOpen,
    setIsFilterOpen,
  } = useSearchHook();

  return (
    <>
      <Box sx={{ minHeight: { xs: 0, sm: 140, md: 80 }, display: 'flex', justifyContent: 'center' }}>
        <ElevationScroll {...props}>
          <StyledAppBar>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                bgcolor: 'background.paper',
                backdropFilter: 'saturate(180%) blur(10px)',
                background: 'rgba(255,255,255,.8)',
                p: { xs: 2, sm: 3 },
                py: { xs: 0, sm: 3 },
              }}
            >
              {isMobileView && (
                <Button
                  onClick={() => setIsFilterOpen((pre) => !pre)}
                  sx={{ maxWidth: 'max-content', alignSelf: 'center' }}
                >
                  {isFilterOpen ? (
                    <Iconify icon={'ic:outline-keyboard-double-arrow-down'} color="primary" width={32} />
                  ) : (
                    <Iconify icon={'ic:outline-keyboard-double-arrow-up'} color="primary" width={32} />
                  )}
                </Button>
              )}
              <Collapse in={isFilterOpen} timeout="auto" unmountOnExit>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        key={isFieldChanged}
                        multiple
                        limitTags={1}
                        fullWidth
                        size="small"
                        id="discipline-listing"
                        options={disciplinesData}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        defaultValue={filterDisciplines()}
                        onChange={(_, value) => handleChange(value, 'disciplineData')}
                        renderInput={(params) => (
                          <TextField {...params} label="Select discipline" placeholder="Discipline" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        key={isFieldChanged}
                        id="qualification-listing"
                        fullWidth
                        size="small"
                        options={qualificationData}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        defaultValue={filterQualification}
                        onChange={(_, value) => handleChange(value, 'qualificationData')}
                        renderInput={(params) => (
                          <TextField {...params} label="Select qualification" placeholder="Qualification" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        key={isFieldChanged}
                        id="country-listing"
                        options={countriesData}
                        fullWidth
                        size="small"
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        defaultValue={filterCountry()}
                        onChange={(_, value) => handleChange(value, 'countryData')}
                        renderInput={(params) => <TextField {...params} label="Select Country" placeholder="Country" />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        required
                        id="search"
                        variant="outlined"
                        name="course"
                        size="small"
                        fullWidth
                        placeholder="Search by course"
                        value={searchText}
                        onChange={(e) => handleChange(e.target.value, e.target.name)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          ...(searchText && {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={resetSearchBar}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      alignSelf: { xs: 'center', sm: 'flex-end' },
                      minWidth: { xs: 1, sm: 2 / 4, md: 1 / 4 },
                      justifyContent: 'space-between',
                      pl: { xs: 0, sm: 1.5 },
                      mb: { xs: 2, sm: 0 },
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={isDisabled}
                      sx={{ py: 1, px: 3, width: 1, color: theme.palette.text.secondary }}
                      onClick={handleReset}
                      color="inherit"
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={isDisabled}
                      sx={{ py: 1, px: 3, width: 1, ml: 0 }}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Stack>
                </Box>
              </Collapse>
            </Stack>
          </StyledAppBar>
        </ElevationScroll>
      </Box>
    </>
    // <StyledWrapper>
    //   <StyledBox component="div">
    //     <Typography variant="h3" color="grey.100">
    //       Explore
    //     </Typography>
    //     <form onSubmit={handleSearch}>
    //       <SearchBar direction="row" spacing={1}>
    //         <TextField
    //           fullWidth
    //           required
    //           id="search"
    //           variant="outlined"
    //           size="small"
    //           sx={{
    //             width: {
    //               xs: 1,
    //               md: 350,
    //             },
    //           }}
    //           placeholder="Search by course"
    //           value={searchText}
    //           onChange={(e) => setSearchText(e.target.value)}
    //           InputProps={{
    //             startAdornment: (
    //               <InputAdornment position="start">
    //                 <SearchIcon />
    //               </InputAdornment>
    //             ),
    //             ...(searchText && {
    //               endAdornment: (
    //                 <InputAdornment position="end">
    //                   <IconButton onClick={resetSearchBar}>
    //                     <CloseIcon />
    //                   </IconButton>
    //                 </InputAdornment>
    //               ),
    //             }),
    //           }}
    //         />
    //         <Button type="submit" variant="contained" sx={{ py: 1, px: 3 }}>
    //           Search
    //         </Button>
    //       </SearchBar>
    //     </form>
    //   </StyledBox>
    // </StyledWrapper>
  );
};

export default Hero;
