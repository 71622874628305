import { Autocomplete, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppContext } from '../../contexts/AppContext';
import useSearchHook from './hooks/useSearchHook';

const StyledWrapper = styled('div')(() => ({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg, #0662a5 0%, #1d2f41 100%)',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: '45vh',
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,.5)',
  [[theme.breakpoints.up('md')]]: {
    padding: theme.spacing(15, 5),
  },
}));

const StyledSearchRow = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  maxWidth: 'max-content',
  borderRadius: theme.spacing(1),
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: `min(80vw, 400px)`,
  [[theme.breakpoints.up('md')]]: {
    width: 'auto',
    minWidth: '150px',
  },
  [[theme.breakpoints.up('lg')]]: {
    minWidth: '200px',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: `min(80vw, 400px)`,
  [[theme.breakpoints.up('md')]]: {
    width: 'auto',
    minWidth: '150px',
  },
}));

const Hero = () => {
  const { disciplinesData, countriesData, qualificationData } = useAppContext();
  const { error, handleSearch, handleChange, courseText } = useSearchHook();

  return (
    <StyledWrapper>
      <StyledPaper elevation={0}>
        <Typography variant="h2" color="grey.100" textAlign="center" mb={{ xs: 3, md: 7 }}>
          Find Your Perfect Course
        </Typography>
        <StyledSearchRow component="form" onSubmit={handleSearch}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <StyledAutocomplete
              multiple
              limitTags={1}
              id="discipline-listing"
              options={disciplinesData}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(_, value) => handleChange(value, 'disciplineData')}
              renderInput={(params) => <TextField {...params} label="Select discipline" placeholder="Discipline" />}
            />
            <StyledAutocomplete
              id="qualification-listing"
              options={qualificationData}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(_, value) => handleChange(value, 'qualificationData')}
              renderInput={(params) => (
                <TextField {...params} label="Select qualification" placeholder="Qualification" />
              )}
            />
            <StyledAutocomplete
              id="country-listing"
              options={countriesData}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(_, value) => handleChange(value, 'countryData')}
              renderInput={(params) => <TextField {...params} label="Select country" placeholder="Country" />}
            />
            <StyledTextField
              variant="outlined"
              name="course"
              label="Search by course"
              value={courseText}
              onChange={(e) => handleChange(e.target.value, 'course')}
            />
            <Button variant="contained" size="large" type="submit" sx={{ minWidth: 200, py: 3.5 }}>
              Search
            </Button>
          </Stack>
          {error && (
            <Typography color="error" variant="body2" mt={1} textAlign="center">
              {error}
            </Typography>
          )}
        </StyledSearchRow>
      </StyledPaper>
    </StyledWrapper>
  );
};

export default Hero;
