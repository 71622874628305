import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Dialog, Box, Typography, DialogContent, TextField, Grid, Button, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MessageAlert from '../alerts/MessageAlert';
import { PHONE_REGEX, SERVER_URL } from '../../config/constants';

const AdmissionDialog = ({ isOpen, handleDialogClose, course = {} }) => {
  const initialForm = { name: '', email: '', phone: '', address: '' };
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(initialForm);

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'phone') {
      if (Number.isNaN(Number(value))) {
        return;
      }
    }
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const dialogClose = () => {
    setError('');
    setFormData(initialForm);
    handleDialogClose();
  };

  const handleSnackbarClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name: username, email, phone, address } = formData;

    if (phone && !PHONE_REGEX.test(phone)) {
      setError('Invalid phone number');
      return;
    }

    if (!Object.values(formData).every((item) => item)) {
      const emptyField = Object.keys(formData).find((item) => !formData[item]);
      if (emptyField) {
        setError(`${emptyField.charAt(0).toUpperCase() + emptyField.slice(1)}  is required`);
        return;
      }
    }
    const body = {
      type: 'admission',
      username,
      email,
      phone: `+92${phone}`,
      address,
      courseName: course.name,
      instituteName: course.institute?.institute_name,
      campus: course.campus?.name,
      specialization: course.specialization,
      degreeLevel: course.degree_level,
      discipline: course.discipline,
      courseLanguage: course.course_language,
      ...(course.base_fee && {
        courseFee: course.course_fee?.length
          ? `${course.course_fee[0].ceil_value} ${course.course_fee[0].currency?.display_name}/Year`
          : '0',
      }),
      courseDuration: course.course_duration,
    };
    setIsLoading(true);
    try {
      await axios.post(`${SERVER_URL}/mail`, body);
      setOpen(true);
    } catch (err) {
      // console.log('error in sending email', err);
    } finally {
      setIsLoading(false);
      dialogClose();
    }
  };
  return (
    <>
      <MessageAlert open={open} handleClose={handleSnackbarClose} message="Applied successfully" />
      <Dialog open={isOpen} onClose={dialogClose}>
        <Box sx={{ position: 'absolute', right: 0, top: 1 }}>
          <Button onClick={dialogClose}>
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <Typography variant="h5" textAlign="center" sx={{ my: 1.5 }}>
            Add Details
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="name"
                  label="Name"
                  autoComplete="off"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="phone"
                  label="Phone"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={formData.phone}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: '+92',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  name="address"
                  label="Address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              {course.id && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      name="course_name"
                      label="Course Name"
                      value={course.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      name="institute_name"
                      label="Institute"
                      value={course.institute?.institute_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      name="campus"
                      label="Campus"
                      value={course.campus?.name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      name="specialization"
                      label="Specialization"
                      value={course.specialization}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      name="degree_level"
                      label="Degree Level"
                      value={course.degree_level}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      name="discipline"
                      label="Discipline"
                      value={course.discipline}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      name="course_language"
                      label="Course Language"
                      value={course.course_language}
                      disabled
                    />
                  </Grid>
                  {course.course_fee?.length && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        name="course_fee"
                        label="Course Fee"
                        value={course.course_fee[0].ceil_value}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {course.course_fee[0].currency?.display_name}/year
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      name="course_duration"
                      label="Course Duration"
                      value={course.course_duration}
                      disabled
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box sx={{ mt: 3, mb: 1, textAlign: 'center' }}>
              {error && (
                <Typography variant="body1" color="error" sx={{ my: 1 }}>
                  {error}
                </Typography>
              )}
              <Button disabled={!!error || isLoading} variant="contained" type="submit">
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

AdmissionDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  course: PropTypes.object,
};

export default AdmissionDialog;
