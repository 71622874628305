import { faker } from '@faker-js/faker';

const countries = [
  {
    id: faker.datatype.uuid(),
    cover: 'assets/uk.jpg',
    name: 'UK',
    icon: 'https://dunyaconsultants.com/wp-content/uploads/2023/04/images-e1681372177392-150x150.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'assets/usa.jpg',
    name: 'USA',
    icon: 'https://dunyaconsultants.com/wp-content/uploads/2022/12/usa-150x150.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'assets/canada.jpg',
    name: 'Canada',
    icon: 'https://dunyaconsultants.com/wp-content/uploads/2023/04/183-1839708_download-flag-icon-of-canada-at-png-format-e1681372366351-150x150.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'assets/australia.jpg',
    name: 'Australia',
    icon: '	https://dunyaconsultants.com/wp-content/uploads/2022/12/flag-1.jpg',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'https://loveincorporated.blob.core.windows.net/contentimages/gallery/b04c9b21-e131-4812-8b27-266db33055b9-german_historic_sites_cologne_cathedral.jpg',
    name: 'Germany',
    icon: 'https://cdn.countryflags.com/thumbs/germany/flag-round-250.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'assets/finland.jpg',
    name: 'Finland',
    icon: 'https://dunyaconsultants.com/wp-content/uploads/2023/04/map-on-flag-button-of-republic-of-finland-vector-4064045-e1681372486772-150x150.jpg',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'https://images.ctfassets.net/3vgdkcmqrnp2/6vmwntnukBXASSaV69RCXI/a296efc447bfad368eb350a1bed88459/62-historical-sites-of-cyprus-7.jpg',
    name: 'Cyprus',
    icon: 'https://cdn.countryflags.com/thumbs/cyprus/flag-round-250.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'https://www.irishtourism.com/contentFiles/page/thumbnailImages/Christ-Church-Cathedral-Portrait.jpg',
    name: 'Ireland',
    icon: 'https://cdn.countryflags.com/thumbs/ireland/flag-round-250.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'https://www.historyhit.com/app/uploads/2020/11/Hagia-Sophia.jpg',
    name: 'Turkey',
    icon: 'https://cdn.countryflags.com/thumbs/turkey/flag-round-250.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'https://images.squarespace-cdn.com/content/v1/590dd4905016e187a50516d7/1687958950471-OXZYXHBR4T1V2TJRSB7D/Frederiksborg+Castle+Fountain.JPG',
    name: 'Denmark',
    icon: 'https://cdn.countryflags.com/thumbs/denmark/flag-round-250.png',
  },
  {
    id: faker.datatype.uuid(),
    cover: 'assets/dubai.webp',
    name: 'Dubai',
    icon: '	https://dunyaconsultants.com/wp-content/uploads/2022/12/dubai-150x150.png',
  },
];

export default countries;
