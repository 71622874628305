import React from 'react';
import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Hero from '../sections/detail/Hero';
import Result from '../sections/detail/Result';

const DetailsPage = () => {
  return (
    <>
      <Helmet>
        <title>Course Details</title>
      </Helmet>
      <Box component="main">
        <Container maxWidth="xl">
          <Hero />
          <Result />
        </Container>
      </Box>
    </>
  );
};

export default DetailsPage;
