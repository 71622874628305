import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

// mui icons
import GamesIcon from '@mui/icons-material/Games';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NfcIcon from '@mui/icons-material/Nfc';

const ItemText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
}));

const TextIcon = ({ Icon }) => <Icon sx={{ mr: 1 }} color="primary" />;

TextIcon.propTypes = {
  Icon: PropTypes.object,
};

const GridSection = ({ result }) => (
  <Grid container spacing={1} sx={{ mt: 2 }}>
    <Grid item xs={12}>
      <ItemText variant="body2">
        <TextIcon Icon={GamesIcon} />
        {result.specialization.slice(0, 28)}
      </ItemText>
    </Grid>
    <Grid item xs={7}>
      <Stack direction="column" spacing={1}>
        <ItemText variant="body2">
          <TextIcon Icon={SchoolIcon} />
          {result.degree_level.slice(0, 15)}
        </ItemText>
        <ItemText variant="body2">
          <TextIcon Icon={NfcIcon} />
          {result.discipline.slice(0, 15)}
        </ItemText>
        {result.base_fee !== null && (
          <ItemText variant="body2">
            <TextIcon Icon={MonetizationOnIcon} />
            {result.course_fee?.length
              ? `${result.course_fee[0]?.ceil_value} ${result.course_fee[0]?.currency?.display_name}
            /Year`
              : '0'}
          </ItemText>
        )}
      </Stack>
    </Grid>
    <Grid item xs={5}>
      <Stack direction="column" spacing={1}>
        <ItemText variant="body2">
          <TextIcon Icon={QueryBuilderIcon} />
          {result.course_duration}
        </ItemText>
        <ItemText variant="body2">
          <TextIcon Icon={LocationOnIcon} />
          {result.country.slice(0, 10)}
        </ItemText>
        <ItemText variant="body2">
          <TextIcon Icon={LanguageIcon} />
          {result.course_language}
        </ItemText>
      </Stack>
    </Grid>
  </Grid>
);

GridSection.propTypes = {
  result: PropTypes.object,
};
export default GridSection;
