import React from "react";
import { Link } from "react-router-dom";
export default function Admindata() {
  const btnContent = [
    { content: "Add Blog", link: "addblog" },
    { content: "All Blog", link: "allposts" },
    { content: "Add Category", link: "addcategory" },
    { content: "All Category", link: "allcategory" }
  ]
  return (
    <div>
      <div className="container mt-4">
        <Link to="board"><button className="btn blog-btn-fill">DASHBOARD</button></Link>
      </div>
      {btnContent && btnContent.map((data) => {
        return <Link className="text-white" to={data.link}>
          <button
            className="btn w-100 text-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=""
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div className="d-flex justify-content-start">
              <div>
                <span className="text-uppercase " style={{ fontSize: "15px", fontWeight: "bolder" }}>{data.content}</span>
              </div>
            </div>
          </button>
        </Link>
      })}

    </div>
  );
}
