import React, { useState } from 'react';
import { Box, Grid, Divider, Typography, Button, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PublicIcon from '@mui/icons-material/Public';
import SupportIcon from '@mui/icons-material/Support';
import PaidIcon from '@mui/icons-material/Paid';
import MoodIcon from '@mui/icons-material/Mood';
import ContactDialog from '../../components/dialogs/ContactDialog';

const StyledStack = styled(Stack)(({ theme }) => ({
  flexBasis: '50%',
  border: `1px solid ${theme.palette.grey[900]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  alignItems: 'center',

  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.grey[100],
    borderColor: theme.palette.grey[100],

    '& svg': {
      color: theme.palette.grey[100],
      transition: 'transform .5s ease',
      transform: 'perspective(400px) rotateY(180deg)',
    },
  },
}));

const StyledIcon = ({ Icon }) => <Icon fontSize="large" color="primary" />;

const ChooseUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ContactDialog isOpen={isOpen} handleDialogClose={handleDialogClose} />
      <Divider />
      <Box id="why-us" component="section" sx={{ py: 5 }}>
        <Typography variant="h3" textAlign="center">
          Why Us?
        </Typography>
        <Grid container spacing={{ xs: 4, md: 4 }} sx={{ mt: { xs: 1, md: 5 } }}>
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h4">Few Reasons To Choose Dunya Educational Consultants!</Typography>
              <Typography variant="body1" textAlign="justify">
                We take pride in our honesty and integrity believing that transparency is essential to building trust
                and lasting relationships. Our commitment to the highest ethical standards is reflected in our work, and
                we are proud of the reputation we have built in the industry.
              </Typography>
              <Box component="div">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: 200, width: { xs: 1, sm: 'max-content' }, py: 3.5 }}
                  onClick={() => setIsOpen(true)}
                >
                  Contact Us
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 2 }}>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={GroupsIcon} />
                <Typography variant="subtitle1">Professional Team</Typography>
              </StyledStack>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={SyncAltIcon} />
                <Typography variant="subtitle1">Ethical Process</Typography>
              </StyledStack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 2 }}>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={PublicIcon} />
                <Typography variant="subtitle1">Connect Globally</Typography>
              </StyledStack>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={SupportIcon} />
                <Typography variant="subtitle1">Personalized Support</Typography>
              </StyledStack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 2 }}>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={PaidIcon} />
                <Typography variant="subtitle1">Refund Claim</Typography>
              </StyledStack>
              <StyledStack direction="row" spacing={2}>
                <StyledIcon Icon={MoodIcon} />
                <Typography variant="subtitle1">Client Satisfaction</Typography>
              </StyledStack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChooseUs;
