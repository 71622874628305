import universityLogo1 from 'src/Assets/Global partners/2560px-University_for_the_Creative_Arts_2015_logo.svg-768x489.png';
import universityLogo2 from 'src/Assets/Global partners/Bangor_university_coat_of_arms.png';
import universityLogo3 from 'src/Assets/Global partners/glos_uni_crest_eaffc56448.jpg';
import universityLogo4 from 'src/Assets/Global partners/Logo-solent.svg-768x409.png';
import universityLogo5 from 'src/Assets/Global partners/logo_full-colour_a3-768x247.png';
import universityLogo6 from 'src/Assets/Global partners/91-768x235.jpg';
import universityLogo7 from 'src/Assets/Global partners/512x512bb.jpg';
import universityLogo8 from 'src/Assets/Global partners/1200px-Etoncollegearms.svg-1-768x882.png';
import universityLogo9 from 'src/Assets/Global partners/1200px-Heriot-Watt_University_logo.svg-768x384.png';
import universityLogo10 from 'src/Assets/Global partners/2560px-Webster_University_Logo.svg-768x379.png';
import universityLogo11 from 'src/Assets/Global partners/1677751631_ic-logo-2023-no-spacing-1.png';
import universityLogo12 from 'src/Assets/Global partners/1689594717_initials-blue-1-768x214.jpg';
import universityLogo13 from 'src/Assets/Global partners/1690180032_gisma-logo.v1-768x310.png';
import universityLogo14 from 'src/Assets/Global partners/Adventus.io_logo_green-768x104.png';
import universityLogo15 from 'src/Assets/Global partners/Arden-Online-logo.png';
import universityLogo16 from 'src/Assets/Global partners/AECC_Brandmark_Horizontal_Midnight_RGB_4-768x205.jpg';
import universityLogo17 from 'src/Assets/Global partners/Avila_University_seal.svg-768x768.png';
import universityLogo18 from 'src/Assets/Global partners/University_of_Europe_for_Applied_Sciences_logo.png';
import universityLogo19 from 'src/Assets/Global partners/UNFC-Logo-RGB-1024x405-2-768x304.png';
import universityLogo20 from 'src/Assets/Global partners/Bahcesehir_University_logo_horizontal.svg-768x275.png';
import universityLogo21 from 'src/Assets/Global partners/CapU_logo_horizontal_rgb-1-768x163.png';
import universityLogo22 from 'src/Assets/Global partners/CCCU-logo-2col-cmyk-768x241.png';
import universityLogo23 from 'src/Assets/Global partners/Halic_University_logo_2022.svg-768x919.png';
import universityLogo24 from 'src/Assets/Global partners/Skills-Australia-Logo-white.png';
import universityLogo25 from 'src/Assets/Global partners/Beykoz_University.jpg';
import universityLogo26 from 'src/Assets/Global partners/kisspng-university-of-northampton-city-university-of-lond-college-logo-5b4901bbbf5ee0.2065595615315112277839-removebg-preview-768x205.png';
import universityLogo27 from 'src/Assets/Global partners/kadir-has-universitesi-logo-3697BBE1F5-seeklogo.com_.png';
import universityLogo28 from 'src/Assets/Global partners/png-transparent-fanshawe-college-bakersfield-college-education-academic-degree-student-text-people-logo-removebg-preview.png';
import universityLogo29 from 'src/Assets/Global partners/concordia-university-chicago-us-768x307.png';
import universityLogo30 from 'src/Assets/Global partners/Adventus.io_logo_green-768x104.png';
import universityLogo31 from 'src/Assets/Global partners/update-logo-768x212.jpg';
import universityLogo32 from 'src/Assets/Global partners/blue_logo-768x118.png';

const universityLogos = [
  universityLogo1,
  universityLogo2,
  universityLogo3,
  universityLogo4,
  universityLogo5,
  universityLogo6,
  universityLogo7,
  universityLogo8,
  universityLogo9,
  universityLogo10,
  universityLogo11,
  universityLogo12,
  universityLogo13,
  universityLogo14,
  universityLogo15,
  universityLogo16,
  universityLogo17,
  universityLogo18,
  universityLogo19,
  universityLogo20,
  universityLogo21,
  universityLogo22,
  universityLogo23,
  universityLogo24,
  universityLogo25,
  universityLogo26,
  universityLogo27,
  universityLogo28,
  universityLogo29,
  universityLogo30,
  universityLogo31,
  universityLogo32,
];

export default universityLogos;
