import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Box, CardMedia, Divider, CardContent, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import GridSection from './GridSection';

const ListView = ({ result, setIsOpen, setSelectedCourse }) => (
  <Grid item xs={12} md={6}>
    <Card sx={{ height: 1 }}>
      <Grid container sx={{ flexDirection: { xs: 'column', sm: 'row' }, height: 1 }}>
        <Grid item xs={11} sm={4} sx={{ alignSelf: 'flex-end' }}>
          <CardContent
            sx={{
              pl: 2,
              pr: 0,
              py: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              rowGap: 1,
              '&:last-child': { pb: 2 },
            }}
          >
            <CardMedia
              component="img"
              alt={result.institute?.institute_name}
              src={result.institute.logo}
              height={140}
              sx={{ objectFit: 'contain' }}
            />

            <Button
              //   size="large"
              variant="contained"
              onClick={() => {
                setSelectedCourse(result);
                setIsOpen((pre) => !pre);
              }}
              sx={{ width: 1 }}
            >
              Apply
            </Button>
          </CardContent>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: { xs: 0, sm: 2 } }} />
        <Grid item xs={11} sm={7}>
          <CardContent
            sx={{
              height: 1,
              py: 2,
              px: { xs: 2, sm: 0 },
              '&:last-child': { pb: 2 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="subtitle2" fontSize="1rem">
              {result.name?.length <= 50 ? result.name : `${result.name.slice(0, 45)}...`}
            </Typography>
            <GridSection result={result} />
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  </Grid>
);

ListView.propTypes = {
  result: PropTypes.object,
  setIsOpen: PropTypes.func,
  setSelectedCourse: PropTypes.func,
};

export default ListView;
