import React from 'react';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';

import Hero from '../sections/home/Hero';
import CardList from '../sections/home/CardList';
import CountryList from '../sections/home/CountryList';
import ChooseUs from '../sections/home/ChooseUs';
import Aboutus from '../sections/home/Aboutus';
import Services from '../sections/home/Services';
import StudyAbroad from '../sections/home/StudyAbroad';
import DuniyaGif from '../sections/home/DuniyaGif';
import GlobalPartners from '../sections/home/GlobalPartners';

const HomePage = () => {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Dunya Course Finder - Top Study Abroad Consultants</title>
      </Helmet>
      <Box component="main" sx={{}}>
        <Hero />
        <StudyAbroad/>
        <Aboutus />
        <Services />
        <Container maxWidth="xl">
          <CountryList />
          <CardList />
          {/*<DuniyaGif/>*/}
          <GlobalPartners/>
          <ChooseUs />
        </Container>
      </Box>
    </>
  );
};

export default HomePage;
