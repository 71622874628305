import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Dialog, Box, Typography, DialogContent, TextField, Grid, Button, MenuItem, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MessageAlert from '../alerts/MessageAlert';
import { SERVER_URL, PHONE_REGEX } from '../../config/constants';
import { useAppContext } from '../../contexts/AppContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ContactDialog = ({ isOpen, ...props }) => {
  const { countriesData } = useAppContext();
  const initialState = { name: '', email: '', phone: '', qualification: '', year: '', city: '', country: '', percentage: '', IELTS: '', budget: '', message: '' };
  const [formData, setFormData] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasTakenTest, setHasTakenTest] = useState('no'); // New state variable

  const handleDialogClose = () => {
    props.handleDialogClose();
    setFormData(initialState);
    setError('');
    setHasTakenTest('no'); // Reset the radio button state
  };

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const handlePhone = (value, country, e, formattedValue) => {
    setError('');
    setFormData((pre) => ({ ...pre, phone: value }));
  };

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'phone') {
      if (Number.isNaN(Number(value))) {
        return;
      }
    }
    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const handleTestChange = (e) => {
    setHasTakenTest(e.target.value);
    if (e.target.value === 'no') {
      setFormData((pre) => ({ ...pre, IELTS: '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
      setError('Invalid phone number');
      return;
    }

    if (!Object.values(formData).every((item) => item)) {
      const emptyField = Object.keys(formData).find((item) => !formData[item]);
      if (emptyField) {
        setError(`${emptyField.charAt(0).toUpperCase() + emptyField.slice(1)} is required`);
        return;
      }
    }

    setIsLoading(true);
    try {
      await axios.post(`${SERVER_URL}/mail`, { ...formData, type: 'contact' });
      setOpen(true);
    } catch (err) {
      // console.log('error in submitting', err);
    } finally {
      setIsLoading(false);
      handleDialogClose();
    }
  };

  return (
    <>
      <MessageAlert open={open} handleClose={handleSnackbarClose} message="Form submitted successfully" />
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <Box sx={{ position: 'absolute', right: 0, top: 1 }}>
          <Button onClick={handleDialogClose}>
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent>
          <Typography variant="h5" textAlign="center" sx={{ my: 1.5 }}>
            Add Details
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="name"
                  label="Name"
                  autoComplete="off"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ width: '100%' }}>
                  <PhoneInput
                    country={'pk'}
                    value={formData.phone}
                    onChange={handlePhone}
                    inputStyle={{
                      width: '100%',
                      height: '40px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                      fontSize: '14px',
                      color: '#000',
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="year"
                  label="Passing year"
                  value={formData.year}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="qualification"
                  label="Educational Qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="city"
                  label="Your City"
                  value={formData.city}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  name="country"
                  label="Country Of Interest"
                  value={formData.country}
                  onChange={handleChange}
                >
                  {countriesData.map((item) => (
                    <MenuItem key={item.slug} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="percentage"
                  label="Percentage/GPA/CGPA"
                  type="number"
                  value={formData.percentage}
                  onChange={handleChange}
                  inputProps={{
                    step: 'any',
                    min: '0',
                    max: '100',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  name="budget"
                  label="Your Budget(PKR)"
                  select
                  value={formData.budget}
                  onChange={handleChange}
                >
                  <MenuItem value="10-20 Lac">10-20 Lac</MenuItem>
                  <MenuItem value="20-30 Lac">20-30 Lac</MenuItem>
                  <MenuItem value="30-40 Lac">30-40 Lac</MenuItem>
                  <MenuItem value="40-50 Lac">40-50 Lac</MenuItem>
                  <MenuItem value="more budget">More Budget</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ my: 1 }}>
                  Have you done PTE or IELTS?
                </Typography>
                <RadioGroup
                  row
                  name="hasTakenTest"
                  value={hasTakenTest}
                  onChange={handleTestChange}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>

              {hasTakenTest === 'yes' && (
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    name="IELTS"
                    label="PTE/IELTS SCORE"
                    autoComplete="off"
                    value={formData.IELTS}
                    onChange={handleChange}
                  />
                </Grid>
              )}



              <Grid item xs={12}>
                <TextField
                  label="Message"
                  name="message"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter your message here"
                />
              </Grid>

            </Grid>
            <Box sx={{ mt: 3, mb: 1, textAlign: 'center' }}>
              {error && (
                <Typography variant="body1" color="error" sx={{ my: 1 }}>
                  {error}
                </Typography>
              )}
              <Button
                disabled={!!error || isLoading}
                variant="contained"
                type="submit"
                sx={{
                  fontSize: '18px',
                  padding: '12px 24px',
                  backgroundColor: '#3f51b5',
                  '&:hover': {
                    backgroundColor: '#f77421',
                  },
                  minWidth: '550px',
                }}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </Box>

          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

ContactDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};
export default ContactDialog;
