import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

const MessageAlert = ({ open, handleClose, message }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={open}
    autoHideDuration={2000}
    onClose={handleClose}
  >
    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

MessageAlert.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
};
export default MessageAlert;
