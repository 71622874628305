import React, { useState, useEffect, useLayoutEffect } from 'react';
// @mui
import {
  Box,
  Grid,
  Typography,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Button,
  Stack,
  Pagination,
  Skeleton,
  Toolbar,
  PaginationItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';

import AdmissionDialog from '../../components/dialogs/AdmissionDialog';
import SortSection from './components/SortSection';
import ListView from './components/ListView';
import GridView from './components/GridView';
import { useAppContext } from '../../contexts/AppContext';
import { API_URL } from '../../config/constants';

const StyledBox = styled(Box)(({ theme }) => ({
  marginBlock: theme.spacing(8),
  [[theme.breakpoints.up('sm')]]: {
    marginBlock: theme.spacing(10),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderColor: theme.palette.text.secondary,
  fontWeight: 'normal',
  marginRight: 4,
}));

const Result = () => {
  const { getSearchResults, coursesData, isLoading, scrollToResult, isMobileView } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isListView, setIsListView] = useState(true);

  useEffect(() => {
    getSearchResults();
  }, []);

  useLayoutEffect(() => {
    if (isMobileView) {
      setIsListView(false);
    } else {
      setIsListView(true);
    }
  }, [isMobileView]);

  const handleDialogClose = () => {
    setIsOpen(false);
    setSelectedCourse({});
  };

  const handlePageChange = (_, value) => {
    setCurrentPage(Number(value));
    getSearchResults({ link: `${API_URL}/?page=${value}` });
    scrollToResult();
  };

  return (
    <>
      <AdmissionDialog isOpen={isOpen} handleDialogClose={handleDialogClose} course={selectedCourse} />
      <StyledBox>
        <Toolbar id="search-result" sx={{ position: 'absolute', top: -200 }} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'flex-end' },
          }}
        >
          <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' } }}>
            {isLoading ? 'Loading...' : coursesData.count ? `${coursesData.count} Results Found` : '0 Result Found'}
          </Typography>
          <Box component="div">
            {!isMobileView && (
              <>
                {isListView ? (
                  <StyledButton
                    size="small"
                    variant="outlined"
                    onClick={() => setIsListView((pre) => !pre)}
                    sx={{ color: 'text.secondary', py: 1 }}
                  >
                    <GridViewIcon /> Grid View
                  </StyledButton>
                ) : (
                  <StyledButton
                    size="small"
                    variant="outlined"
                    onClick={() => setIsListView((pre) => !pre)}
                    sx={{ color: 'text.secondary', py: 1 }}
                  >
                    <FormatListBulletedIcon /> List View
                  </StyledButton>
                )}
              </>
            )}
            <SortSection />
          </Box>
        </Stack>
        <Divider variant="fullWidth" sx={{ mb: 3, height: 5 }} />
        {isLoading ? (
          <Grid container spacing={2}>
            {Array.from(new Array(4)).map((_, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={118} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton />
                  <Skeleton width="50%" />
                  <Skeleton width="50%" />
                  <Skeleton width="50%" />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : !coursesData?.results?.length ? (
          <Typography>No result</Typography>
        ) : (
          <>
            <Grid container spacing={2}>
              {coursesData.results.map((result) =>
                isListView ? (
                  <ListView
                    key={result.id}
                    result={result}
                    setIsOpen={setIsOpen}
                    setSelectedCourse={setSelectedCourse}
                  />
                ) : (
                  <GridView
                    key={result.id}
                    result={result}
                    setIsOpen={setIsOpen}
                    setSelectedCourse={setSelectedCourse}
                  />
                )
              )}
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Pagination
                variant="outlined"
                color="primary"
                siblingCount={3}
                count={coursesData?.total_pages}
                page={currentPage}
                onChange={handlePageChange}
                renderItem={(item) =>
                  item.type === 'page' ? (
                    <PaginationItem sx={{ mt: 0.5 }} {...item} disabled={item.selected} />
                  ) : (
                    <PaginationItem sx={{ mt: 0.5 }} {...item} />
                  )
                }
              />
            </Box>
          </>
        )}
      </StyledBox>
    </>
  );
};

export default Result;
