import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { HashLink } from 'react-router-hash-link';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, width = '120px', sx = {} }) => {
  const logo = <img src="/assets/logo.png" alt="Course Finder" width={width} />;
  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Box sx={sx}>
      <HashLink smooth to="/#">
        {logo}
      </HashLink>
    </Box>
  );
});

Logo.propTypes = {
  width: PropTypes.string,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
