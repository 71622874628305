import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
//
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Typography)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 52,
  height: 52,
  position: 'absolute',
  left: 'calc(50% - 52px/2)',
  bottom: theme.spacing(-4.5),
  '&:hover': {
    transition: 'all .5s ease',
    transform: 'scale(1.2)',
  },
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CountryCard.propTypes = {
  country: PropTypes.object.isRequired,
};

export default function CountryCard({ country }) {
  const { cover, name, icon } = country;

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Card>
        <StyledCardMedia>
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 87,
              height: 36,
              zIndex: 9,
              bottom: -17,
              position: 'absolute',
              color: 'background.paper',
              left: 'calc(50% - 87px/2)',
            }}
          />
          <StyledAvatar alt={name} src={icon} />

          <StyledCover alt={name} src={cover} />
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 6,
          }}
        >
          <StyledTitle color="inherit" variant="subtitle1" textAlign="center" sx={{ fontSize: '1.2rem' }}>
            {name}
          </StyledTitle>
        </CardContent>
      </Card>
    </Grid>
  );
}
