import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useAppContext } from '../../../contexts/AppContext';

const useSearchHook = () => {
  const {
    setSearchBody,
    disciplinesData,
    searchBody,
    countriesData,
    qualificationData,
    scrollToResult,
    getSearchResults,
    resetSearchBody,
    isMobileView,
  } = useAppContext();
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedQualification, setSelectedQualification] = useState({});
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const isDisabled =
    !selectedDisciplines.length && !selectedCountry?.slug && !selectedQualification?.slug && !searchText;

  const filterDisciplines = useCallback(
    () => disciplinesData?.filter((item) => searchBody?.disciplines?.includes(item?.slug)),
    [disciplinesData, searchBody.disciplines]
  );
  const filterCountry = useCallback(
    () => countriesData?.find((item) => searchBody?.countries?.includes(item?.slug)),
    [countriesData, searchBody.countries]
  );
  const filterQualification = useCallback(
    () => qualificationData?.find((item) => searchBody?.degree_levels_types?.includes(item?.slug)),
    [qualificationData, searchBody.degree_levels_types]
  );

  useLayoutEffect(() => {
    if (isMobileView) {
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
    }
  }, [isMobileView]);

  // Set course data
  useEffect(() => {
    if (searchBody?.course?.length) setSearchText(searchBody.course[0]);
    else setSearchText('');
  }, [searchBody?.course]);

  //   Set Disciplines Data
  useEffect(() => {
    setSelectedDisciplines(filterDisciplines());
  }, [searchBody.disciplines, disciplinesData, filterDisciplines]);

  //   Set Countries Data
  useEffect(() => {
    setSelectedCountry(filterCountry());
  }, [searchBody.countries, countriesData, filterCountry]);

  // Set Qualifications Data
  useEffect(() => {
    setSelectedQualification(filterQualification());
  }, [searchBody.degree_levels_types, qualificationData, filterQualification]);

  const resetSearchBar = () => {
    setSearchText('');
  };

  const handleChange = (value, name) => {
    if (name === 'countryData') {
      setSelectedCountry(value);
    } else if (name === 'disciplineData') {
      setSelectedDisciplines(value);
    } else if (name === 'qualificationData') {
      setSelectedQualification(value);
    } else if (name === 'course') {
      setSearchText(value);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (isMobileView) setIsFilterOpen(false);

    const body = {
      course: searchText?.length ? [searchText] : [],
      disciplines: selectedDisciplines?.length ? selectedDisciplines.map((item) => item.slug) : [],
      countries: selectedCountry?.slug ? [selectedCountry.slug] : [],
      degree_levels_types: selectedQualification?.slug ? [selectedQualification.slug] : [],
    };
    setSearchBody(body);
    scrollToResult();
    await getSearchResults({ body });
  };

  const handleReset = async () => {
    scrollToResult();
    setSearchText('');
    setSelectedDisciplines([]);
    setSelectedCountry({});
    setSelectedQualification({});
    setIsFieldChanged((pre) => !pre);
    if (isMobileView) setIsFilterOpen(false);
    try {
      const result = resetSearchBody();
      await getSearchResults({ body: result });
    } catch (err) {
      console.error('error in resetting', err);
    }
  };

  return {
    handleChange,
    handleSearch,
    isDisabled,
    resetSearchBar,
    searchText,
    filterDisciplines,
    filterCountry,
    filterQualification,
    handleReset,
    isFieldChanged,
    isFilterOpen,
    setIsFilterOpen,
  };
};

export default useSearchHook;
